import {
  Component,
  ElementRef,
  EventEmitter,
  forwardRef,
  HostListener,
  Input,
  NgZone,
  OnChanges,
  OnInit,
  Output,
  SimpleChanges,
  ViewChild,
  OnDestroy
} from '@angular/core';
import { CommonModule } from '@angular/common';
import { FormsModule, ControlValueAccessor, NG_VALUE_ACCESSOR } from '@angular/forms';
import { PlaceService } from '../shared/services/places/places.service';

export interface PlaceSearchResult {
  address: string;
  location?: { lat: number, lng: number };
  postcode?: string;
  city?: string;
  country?: string;
}

@Component({
  selector: 'app-place-autocomplete',
  standalone: true,
  imports: [CommonModule, FormsModule],
  templateUrl: './place-autocomplete.component.html',
  styleUrls: ['./place-autocomplete.component.scss'],
  providers: [
    {
      provide: NG_VALUE_ACCESSOR,
      useExisting: forwardRef(() => PlaceAutocompleteComponent),
      multi: true,
    },
  ],
})
export class PlaceAutocompleteComponent implements OnInit, OnChanges, ControlValueAccessor, OnDestroy {
  @ViewChild('inputField') inputField!: ElementRef;

  @Input() placeholder = 'Enter address...';
  @Input() address: string = '';
  @Output() addressChange = new EventEmitter<string>();
  @Output() placeChanged = new EventEmitter<PlaceSearchResult>();

  @Input() inputClass: string = '';

  suggestions: any[] = []; 
  isSuggestionSelected = false; 
  onChange: any = () => {};
  onTouched: any = () => {};

  constructor(private ngZone: NgZone, private placeService: PlaceService, private elementRef: ElementRef) {}

  ngOnInit() {}

  ngAfterViewInit() {}

  onAddressInputChange(): void {
    this.isSuggestionSelected = false; 
    if (this.address && this.address.length > 3) {
      this.placeService.getPlaceSuggestions(this.address).subscribe(
        (suggestions) => {
          this.suggestions = suggestions;
        },
        (error) => {
          console.error('Erreur lors de la récupération des suggestions de lieux :', error);
        }
      );
    } else {
      this.suggestions = [];
    }
  }

  selectSuggestion(suggestion: any) {
    this.isSuggestionSelected = true; 
    this.address = suggestion.description;
    this.suggestions = []; 
    this.placeService.getPlaceDetails(suggestion.place_id).subscribe(
      (result) => {
        const placeDetails = this.extractPlaceDetails(result);
        this.address = placeDetails.address;
        this.addressChange.emit(this.address);
        this.placeChanged.emit(placeDetails);
        this.onChange(this.address);
        this.isSuggestionSelected = false;
      },
      (error) => {
        console.error('Erreur lors de la récupération des détails du lieu :', error);
      }
    );
  }
  
  extractPlaceDetails(place: any): PlaceSearchResult {
    const addressParts = place.address ? place.address.split(',') : [];
    const shortAddress = addressParts.length > 0 ? addressParts[0].trim() : place.address;

    return {
      address: shortAddress,
      location: place.location || undefined,
      postcode: place.postcode || '',
      city: place.city || '',
      country: place.country || ''
    };
  }

  @HostListener('document:click', ['$event'])
  onClickOutside(event: MouseEvent) {
    if (!this.elementRef.nativeElement.contains(event.target)) {
      this.suggestions = []; 
    }
  }

  onBlur() {
    if (!this.isSuggestionSelected) {
      this.addressChange.emit(this.address);
      this.placeChanged.emit({
        address: this.address,
        location: undefined,
        postcode: undefined,
        city: undefined,
        country: undefined
      });
      this.onChange(this.address);
    }
  }

  getPostcode(place: any): string | undefined {
    const addressComponent = place?.address_components?.find((component: any) =>
      component.types.includes('postal_code')
    );
    return addressComponent?.long_name;
  }

  getCity(place: any): string | undefined {
    const addressComponent = place?.address_components?.find((component: any) =>
      component.types.includes('locality') || component.types.includes('sublocality') || component.types.includes('administrative_area_level_2')
    );
    return addressComponent?.long_name;
  }

  getCountry(place: any): string | undefined {
    const addressComponent = place?.address_components?.find((component: any) =>
      component.types.includes('country')
    );
    return addressComponent?.long_name;
  }

  ngOnChanges(changes: SimpleChanges): void {
    if (changes['address']) {
      this.updateInputField();
    }
  }

  writeValue(value: any): void {
    if (value !== undefined) {
      this.address = value;
      this.updateInputField();
    }
  }

  registerOnChange(fn: any): void {
    this.onChange = fn;
  }

  registerOnTouched(fn: any): void {
    this.onTouched = fn;
  }

  setDisabledState?(isDisabled: boolean): void {
    if (this.inputField) {
      this.inputField.nativeElement.disabled = isDisabled;
    }
  }

  private updateInputField(): void {
    if (this.inputField) {
      this.inputField.nativeElement.value = this.address;
    }
  }

  ngOnDestroy() {
  }
}
