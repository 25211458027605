import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { Observable } from 'rxjs';
import { PlaceSearchResult } from '../../../place-autocomplete/place-autocomplete.component';
import { environment } from '../../../../environments/environment';

@Injectable({
  providedIn: 'root'
})
export class PlaceService {
    private placeUrl = `${environment.apiUrl}/google-maps`;

  constructor(private http: HttpClient) {}

  getPlaceDetails(placeId: string): Observable<PlaceSearchResult> {
    return this.http.get<PlaceSearchResult>(`${this.placeUrl}/place-details`, {
      params: { placeId }  
    });
  }

  getPlaceSuggestions(input: string): Observable<any[]> {
    return this.http.get<any[]>(`${this.placeUrl}/place-suggestions`, {
      params: { input }
    });
  }
}
