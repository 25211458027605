<div class="position-relative">
  <input
    type="text"
    [placeholder]="placeholder"
    [(ngModel)]="address"
    #inputField
    (input)="onAddressInputChange()"
    (blur)="onBlur()"
    [ngClass]="['input-autocomplete', 'form-control', inputClass]"
    class="form-control"
  />
  <ul *ngIf="suggestions.length > 0" class="dropdown-menu show position-absolute w-100" style="top: 100%;">
    <li *ngFor="let suggestion of suggestions" class="dropdown-item" (click)="selectSuggestion(suggestion); $event.stopPropagation()">
      {{ suggestion.description }}
    </li>
  </ul>
</div>
