import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { Observable, of, switchMap, tap, map, catchError, throwError } from 'rxjs';
import { environment } from '../../../../environments/environment';

@Injectable({
  providedIn: 'root'
})
export class InseeService {
  private url = `${environment.apiUrl}`;
  private apiUrlSiret = `${this.url}/api-insee/siret`;
  private apiUrlSiretsBySiren = `${this.url}/api-insee/siren`;
  private tokenUrl = `${this.url}/api-insee/token`;
  private token: string | null = null;
  private tokenExpiration: Date | null = null;

  constructor(private http: HttpClient) {}

  private getToken(): Observable<string> {
    if (this.token && this.tokenExpiration && new Date() < this.tokenExpiration) {
      return of(this.token);
    } else {
      return this.http.post<any>(this.tokenUrl, {}).pipe(
        tap(response => {
          this.token = response.access_token;
          this.tokenExpiration = new Date(new Date().getTime() + response.expires_in * 1000);
        }),
        map(response => response.access_token)
      );
    }
  }

  getEnterpriseInfo(identifier: string): Observable<any> {
    return this.getToken().pipe(
      switchMap(token => {
        let url = '';
        if (identifier.length === 14) {
          url = `${this.apiUrlSiret}/${identifier}?token=${token}`;
        } else if (identifier.length === 9) {
          url = `${this.apiUrlSiretsBySiren}/${identifier}?token=${token}`;
        }
        return this.http.get(url).pipe(
        );
      })
    );
  }  
}
